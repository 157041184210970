import {
  PreferredArticleInstitute,
  UserPreferencesService
} from '@atreus/data';
import { createStore } from '@qcx/store';

export interface EditorialsStoreState {
  userPreferences: PreferredArticleInstitute[];
}

const initialState: EditorialsStoreState = {
  userPreferences: []
};

function addPreference(
  state: EditorialsStoreState,
  editorialId: number
): EditorialsStoreState {
  return {
    ...state,
    userPreferences: [
      ...state.userPreferences,
      { kratosAtreusCategoryId: editorialId }
    ]
  };
}

function removePreference(
  state: EditorialsStoreState,
  editorialId: number
): EditorialsStoreState {
  return {
    ...state,
    userPreferences: state.userPreferences.filter(
      institute => institute.kratosAtreusCategoryId !== editorialId
    )
  };
}

interface ApiPreferenceResponse {
  kratosAtreusCategoryId: number;
}

const userPreferencesService = new UserPreferencesService({
  credentials: 'include'
});

async function fetchUserPreferences(): Promise<ApiPreferenceResponse[]> {
  let userPreferences: PreferredArticleInstitute[] | undefined = [];

  const user = await userPreferencesService.listAtreusV2UserPreferences();
  userPreferences = user.institutesAttributes;

  return userPreferences;
}

const { Provider, useStore } = createStore(initialState, ({ set, get }) => {
  return {
    setUserPreferences: async () => {
      try {
        const preferences = await fetchUserPreferences();
        set(state => ({ ...state, userPreferences: preferences }));
      } catch {
        set(state => ({ ...state, userPreferences: [] }));
      }
    },
    add: async (editorialId: number) => {
      set(state => addPreference(state, editorialId));

      const requestBody = {
        preferredArticleInstitutesAttributes: [
          {
            kratosAtreusCategoryId: editorialId
          }
        ]
      };

      try {
        await userPreferencesService.putAtreusV2UserPreferences({
          requestBody
        });
      } catch {
        set(state => removePreference(state, editorialId));
      }
    },
    delete: async (editorialId: number) => {
      set(state => removePreference(state, editorialId));

      try {
        await userPreferencesService.deleteAtreusV2UserPreference({
          kratosAtreusInstituteId: editorialId
        });
      } catch {
        set(state => addPreference(state, editorialId));
      }
    },
    isCurrentlyFollowed: (editorialId: number) => {
      return get().userPreferences.some(
        institute => institute.kratosAtreusCategoryId === editorialId
      );
    }
  };
});

export const EditorialsStoreProvider = Provider;
export const useEditorialsStore = useStore;
